/**
 * Mobile Menu                                                                                                                                          [description]
 */
jQuery(document).ready(function($){

	$('#mobile-menu').click(function(){
		$(this).toggleClass('open');

		$('.mobile-menu').fadeToggle();
		$('.utility').fadeToggle();
	});

	// Open Mobile Sub-menu
	jQuery(".mobile-menu .menu-item-has-children").click(function(){
		jQuery(this).toggleClass('open').children('.sub-menu').slideToggle();
	});
	jQuery(".menu-item-has-children ul").click(function(e) {
		e.stopPropagation();
	});

	jQuery(".menu-item-has-children li").click(function(e) {
		e.stopPropagation();
	});

	jQuery(".menu-item-has-children a").click(function(e) {
		e.stopPropagation();
	});

	jQuery('.page-id-13 .et_pb_slide_3.et_pb_slide .et_pb_container a.et_pb_more_button.et_pb_button').attr('target', '_blank');

});


/**
 * Optional Pinned Header
 */
jQuery(window).scroll(function (event) {
    var scroll = jQuery(window).scrollTop();

    if(scroll > 1){

    	jQuery('header').addClass('scroll');

        // jQuery('.title img').attr('src', '/wp-content/themes/kotler/assets/images/logo-scroll.png');

    } else {

    	jQuery('header').removeClass('scroll');

        //jQuery('.title img').attr('src', '/wp-content/themes/kotler/assets/images/logo.png');

    }
});
